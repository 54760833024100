import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Add this line at the top
import Footer from "../components/Footer";
import Navbar from "./Navbar";
import LogoImage from "../assets/images/amda_solutions_logo.png";
import { FaLaptopCode, FaMobileAlt } from "react-icons/fa";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      {/* Header */}
      <Navbar />

      {/* Services Section */}
      <main className="mt-24 p-6">
        <div className="max-w-7xl mx-auto">
          <div className="text-center  mb-8">
            <h1 className="text-2xl font-bold text-primary text-center mb-4">
              Our Services
            </h1>
            <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
          </div>

          {/* Services Cards */}
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
            {/* Consulting Services */}
            <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
              <FaLaptopCode className="text-4xl text-teal-500 mx-auto mb-4" />
              <h3 className="text-2xl font-bold text-center text-gray-800">
                Consulting Services
              </h3>
              <p className="mt-4 text-gray-600 text-center">
                AMDA offers flexible pricing models for projects. Either it be
                fixed bid projects or time-based services, we are committed to
                successful completion of the project needs. We also offer
                support services to existing projects at great pricing models.
                Our professionals go through continuous training and learning
                exercises and keep up with technology changes in their
                respective domains to deliver high-quality services to the
                client. We have extensive experience in several domains which
                includes Retail, Telecommunications, Fintech, and Government
                entities.
              </p>
            </div>

            {/* Custom Application Development */}
            <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
              <FaMobileAlt className="text-4xl text-indigo-500 mx-auto mb-4" />
              <h3 className="text-2xl font-bold text-center text-gray-800">
                Custom App Development
              </h3>
              <p className="mt-4 text-gray-600 text-center">
                We specialize in developing custom applications for several
                business needs using the latest technology available. Our
                professionals have expert-level skills in Java-related
                technologies, Enterprise Content Management products like
                FileNet, Database products like Oracle/SQL Server, Image Data
                extraction products like Datacap/Kofax, reporting products like
                SAS, Testing tools like Selenium. Our professionals are
                well-trained on the latest product versions available in the
                market. When developing and delivering custom applications, our
                core mission is always trying to reduce costs for maintenance,
                licensing, and further customization.
              </p>
            </div>

            {/* Cloud Solutions */}
            {/* <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
            <FaCloud className="text-4xl text-blue-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-center text-gray-800">Cloud Solutions</h3>
            <p className="mt-4 text-gray-600 text-center">
              Migrate to cloud-based solutions and unlock the potential of scalability, flexibility, and performance.
            </p>
          </div> */}

            {/* Data Analytics */}
            {/* <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
            <FaDatabase className="text-4xl text-purple-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-center text-gray-800">Data Analytics</h3>
            <p className="mt-4 text-gray-600 text-center">
              Leverage data analytics to gain valuable insights and make data-driven decisions for business growth.
            </p>
          </div> */}

            {/* Mobile App Development */}
            {/* <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
            <FaMobileAlt className="text-4xl text-pink-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-center text-gray-800">Mobile App Development</h3>
            <p className="mt-4 text-gray-600 text-center">
              Create innovative, user-friendly mobile applications for both Android and iOS platforms.
            </p>
          </div> */}

            {/* AI and ML Solutions */}
            {/* <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
            <FaLaptopCode className="text-4xl text-yellow-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-center text-gray-800">AI & Machine Learning</h3>
            <p className="mt-4 text-gray-600 text-center">
              Build intelligent solutions using AI and ML technologies to transform your business operations.
            </p>
          </div> */}
          </div>
        </div>
        {/* Hero Section */}
        <section
          className="relative mt-16 bg-cover bg-center h-[60vh]"
          style={{ backgroundImage: "url('/assets/images/hero-banner.jpg')" }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white">
            <h1 className="text-4xl font-extrabold text-center">
              Transform Your Business with AMDA Software Solutions
            </h1>
            <p className="mt-4 text-lg text-gray-200 text-center max-w-xl">
              Providing innovative solutions tailored to your business needs
              using cutting-edge technology.
            </p>
            <a
              href="/contact-us"
              className="mt-6 px-8 py-3 bg-yellow-400 text-black font-bold rounded-full shadow-lg hover:bg-yellow-300 transition duration-300"
            >
              Contact Us
            </a>
          </div>
        </section>
      </main>

      {/* Footer */}
      <Footer />
    </React.Fragment>
  );
};

export default Services;
