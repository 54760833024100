import React from 'react';

const features = [
  {
    title: 'Consulting Services',
    description:
      'AMDA offers flexible pricing models for projects. Either it be fixed bid projects or time-based services, we are committed to successful completion of the project needs. We also offer support services to existing projects at great pricing models. Our professionals go through continuous training and learning exercises and keep up with technology changes in their respective domains to deliver high-quality services to the client. We have extensive experience in several domains which includes Retail, Telecommunications, Fintech, and Government entities.',
    icon: '💼',
  },
  {
    title: 'Custom Application Development',
    description:
      'We specialize in developing custom applications for several business needs using the latest technology available. Our professionals have expert-level skills in Java-related technologies, Enterprise Content Management products like FileNet, Database products like Oracle/SQL Server, Image Data extraction products like Datacap/Kofax, reporting products like SAS, Testing tools like Selenium. Our professionals are well-trained on the latest product versions available in the market. When developing and delivering custom applications, our core mission is always trying to reduce costs for maintenance, licensing, and further customization.',
    icon: '💻',
  },
];

const FeaturesSection = () => (
  <section className="bg-background py-16 px-8">
    {/* About Us Section */}
    <div className="mb-12">
      <div className="text-center  mb-8">
        <h1 className="text-4xl font-bold text-primary text-center mb-4">About Us</h1>
        <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
      </div>
      <p className="text-center text-gray-700 max-w-3xl mx-auto leading-relaxed">
        AMDA Software Solutions Inc has a core mission to deliver high-quality software services to business needs.
        We have extensive experience in the complete Software Development Life Cycle, right from gathering
        requirements, developing, testing, and delivering web-scale applications to several clients. If time or money
        is important for your needs, AMDA Software Solution Inc is the one-stop shop for all your software needs.
      </p>
    </div>

    {/* Features Section */}
    <div>
      <div className="text-center  mb-8">
        <h1 className="text-4xl font-bold text-primary text-center mb-4">Our Services</h1>
        <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
      </div>

      <div className="grid gap-8 md:grid-cols-2">
        {features.map((feature, index) => (
          <div
            key={index}
            className="p-6 bg-white shadow-lg rounded-lg text-center"
          >
            <div className="text-4xl">{feature.icon}</div>
            <h3 className="mt-4 text-xl font-semibold text-gray-900">
              {feature.title}
            </h3>
            <p className="mt-2 text-gray-700">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default FeaturesSection;
