import React, { useState } from "react";
import amdaLogo from "../assets/images/amda_solutions_logo.png";

const EmployeeLogin = () => {
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    if (!username || !password) {
      setError("Both username and password are required.");
    } else {
      setError("");
      // Submit form or call API for login here
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white shadow-xl rounded-lg mt-20 overflow-hidden mb-auto">
        {/* Left Section: Company Logo */}
        <div className="hidden md:flex w-1/2 items-center justify-center bg-slate-400">
          <img src={amdaLogo} alt="AMDA Solutions Logo" className="w-3/4" />
        </div>

        {/* Right Section: Login Form */}
        <div className="w-full md:w-1/2 p-8">
          <h3 className="text-3xl font-semibold text-center mb-8 text-gray-800">
            Employee Login
          </h3>

          {/* Error Message */}
          {error && (
            <p className="text-red-500 text-sm text-center mb-4">{error}</p>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit}>
            {/* Input Fields */}
            <div>
              <label
                htmlFor="username"
                className="block text-bold font-medium text-gray-700"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                className="w-full p-3 mt-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Enter your username"
                required
              />
              <label
                htmlFor="password"
                className="block text-bold font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="w-full p-3 mt-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Enter your password"
                required
              />
            </div>

            {/* Remember me & Forgot Password */}
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <input type="checkbox" id="remember" className="mr-2" />
                <label htmlFor="remember" className="text-bold text-blue-600">
                  Remember me
                </label>
              </div>
              <a href="#!" className="text-blue-600 text-bold hover:underline">
                Forgot password?
              </a>
            </div>

            {/* Login Button */}
            <div className="text-center">
              <button
                type="submit"
                className="w-full md:w-1/2 bg-orange-500 text-white p-3 rounded-full hover:bg-orange-600 transition duration-300"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="w-full bg-orange-500 text-white py-4 mt-8 text-center">
        <div className="container mx-auto">
          <p>&copy; 2024 AMDA Software Solutions. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default EmployeeLogin;
