import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for routing
import Footer from "../components/Footer"; // Import Footer component
import Navbar from "./Navbar";
import LogoImage from "../assets/images/amda_solutions_logo.png"; // Import the logo image



const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Navbar />

      {/* Main Content Section */}
      <main className="mt-24 p-6">
        <section>
          <div className="text-center  mb-8">
            <h1 className="text-4xl font-bold text-primary text-center mb-4">Contact Us</h1>
            <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
          </div>
          <p className="text-lg text-gray-700 mb-8">
            We're committed to providing you with exceptional support. Whether you have questions, need assistance, or want more details about our products and services, feel free to reach out. Our team is here to provide you with the information you need.
          </p>
          <h3 className="text-3xl font-semibold text-primary mb-6">Tell Us How We Can Help</h3>
          <p className="text-lg text-gray-700 mb-8">
            Please fill out the quick form and we’ll be in touch shortly!
          </p>

          {/* Contact Form and Headquarters Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Contact Form */}
            <div className="bg-white shadow-2xl rounded-2xl p-4">
              <form className="space-y-2">
                <div>
                  <label className="block text-lg font-medium text-gray-700">Full Name*</label>
                  <input type="text" className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none" placeholder="Enter your first name" required />
                </div>
                <div>
                  <label className="block text-lg font-medium text-gray-700">Email*</label>
                  <input type="email" className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none" placeholder="Enter your email" required />
                </div>
                <div>
                  <label className="block text-lg font-medium text-gray-700">Phone*</label>
                  <input type="text" className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none" placeholder="Enter your phone number" required />
                </div>
                <div>
                  <label className="block text-lg font-medium text-gray-700">Reason for Inquiry*</label>
                  <input type="text" className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none" placeholder="Enter your Reason for Inquiry" required />
                </div>
                <div>
                  <label className="block text-lg font-medium text-gray-700">Message*</label>
                  <textarea className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none" placeholder="Enter your message" required></textarea>
                </div>
                <div className="flex items-center">
                  <input type="checkbox" className="mr-2" required />
                  <label className="text-gray-700">
                    I agree to the privacy policy & cookie policy of AMDA Software Solutions.
                  </label>
                </div>
                <button className="w-1/2 px-6 py-3 bg-primary text-white font-semibold rounded-lg shadow-md hover:bg-accent transition-all">
                  Send Message
                </button>
              </form>
            </div>

            {/* Headquarters Section */}
            <div className="flex flex-col justify-center space-y-2 bg-gradient-to-br from-orange-400 to-orange-500 text-white rounded-lg p-4 shadow-lg">
              <h2 className="text-3xl font-semibold text-white mb-6">Headquarters</h2>
              <p className="text-lg text-white mb-4">
                <strong>Address:</strong> 5002 Lenker Street, Suite#108, Mechanicsburg, PA 17050.
              </p>
              <p className="text-lg text-white mb-4">
                <strong>Phone:</strong> (347) 821 8588
              </p>
              <p className="text-lg text-white">
                <strong>Email:</strong> <a href="mailto: contact@amdasolutions.com" className="text-white"> contact@amdasolutions.com</a>
              </p>
            </div>
          </div>

          {/* Map Section */}
          <section className="mt-12">
            <h3 className="text-3xl font-semibold text-primary mb-6">Our Location</h3>
            <div className="w-full h-96">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10150.702263153782!2d-76.8928839!3d40.2292878!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c63adf1393c66b%3A0xbe73529e88dbfb47!2s5002%20Lenker%20St%2C%20Mechanicsburg%2C%20PA%2017050!5e0!3m2!1sen!2sus!4v1698390497389!5m2!1sen!2sus"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </section>
        </section>
      </main>

      {/* Footer Section */}
      <Footer /> {/* Add Footer at the bottom of the page */}
    </React.Fragment>
  );
};

export default ContactUs;
