import React, {useEffect } from "react";
import { Link } from 'react-router-dom';
import Navbar from "./Navbar";
import Footer from '../components/Footer'; // Import Footer component
import LogoImage from "../assets/images/amda_solutions_logo.png"; // adjust the path accordingly

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Navbar />

      {/* Main Content Section */}
      <main className="mt-24 p-6">
        <div className="container mx-auto">

          {/* About the Company */}
          <section className="mb-12">
            <div className="text-center  mb-8">
              <h1 className="text-2xl font-bold text-primary text-center mb-4">Our Story</h1>
              <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
            </div>
            <p className="text-lg text-gray-700 leading-relaxed">
              AMDA Software Solutions Inc has a core mission to deliver high quality software services to business needs. We have extensive experience in the complete Software Development Life Cycle, right from gathering requirements, developing , testing and delivering web scale applications to several clients. If time or money is important for your needs, AMDA Software Solution Inc is the one stop shop for all your software needs.
            </p>
          </section>

          {/* Our Mission */}
          <section className="mb-12">
            <div className="text-center  mb-8">
              <h1 className="text-2xl font-bold text-primary text-center mb-4">Our Mission</h1>
              <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
            </div>
            <p className="text-lg text-gray-700 leading-relaxed">
              Our mission is to empower businesses and individuals by providing innovative software solutions that
              drive efficiency, growth, and success. We aim to make technology accessible, understandable, and useful
              to everyone, regardless of their level of expertise.
            </p>
          </section>

          {/* Our Vision */}
          {/* <section className="mb-12">
            <div className="text-center  mb-8">
              <h1 className="text-2xl font-bold text-primary text-center mb-4">Our Vision</h1>
              <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
            </div>
            <p className="text-lg text-gray-700 leading-relaxed">
              To be a global leader in software solutions and training, recognized for our commitment to excellence,
              our customer-centric approach, and our ability to deliver solutions that create long-term value.
            </p>
          </section> */}

          {/* Meet Our Team */}
          {/* <section className="mb-12">
            <div className="text-center  mb-8">
              <h1 className="text-3xl font-bold text-primary text-center mb-4">Meet Our Team</h1>
              <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"> */}
          {/* Team Member 1 */}
          {/* <div className="text-center bg-white p-6 shadow-2xl rounded-2xl transition transform hover:scale-105 hover:shadow-xl">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Team Member 1"
                  className="w-32 h-32 mx-auto rounded-full border-4 border-primary"
                />
                <h3 className="text-xl font-semibold text-primary mt-4">John Doe</h3>
                <p className="text-gray-600">CEO & Founder</p>
                <p className="text-gray-700 mt-2">John leads the company with a vision for technological innovation and a commitment to excellence in service delivery.</p>
              </div> */}

          {/* Team Member 2 */}
          {/* <div className="text-center bg-white p-6 shadow-2xl rounded-2xl transition transform hover:scale-105 hover:shadow-xl">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Team Member 2"
                  className="w-32 h-32 mx-auto rounded-full border-4 border-primary"
                />
                <h3 className="text-xl font-semibold text-primary mt-4">Jane Smith</h3>
                <p className="text-gray-600">CTO</p>
                <p className="text-gray-700 mt-2">Jane oversees our technology strategy, ensuring that our solutions are at the forefront of innovation and best practices.</p>
              </div> */}

          {/* Team Member 3 */}
          {/* <div className="text-center bg-white p-6 shadow-2xl rounded-2xl transition transform hover:scale-105 hover:shadow-xl">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Team Member 3"
                  className="w-32 h-32 mx-auto rounded-full border-4 border-primary"
                />
                <h3 className="text-xl font-semibold text-primary mt-4">Michael Brown</h3>
                <p className="text-gray-600">Lead Developer</p>
                <p className="text-gray-700 mt-2">Michael ensures that all of our projects are delivered with high-quality code and cutting-edge technologies.</p>
              </div>
            </div>
          </section> */}

          {/* Contact Information */}
          <section className="mb-12">
            <div className="text-center  mb-8">
              <h1 className="text-3xl font-bold text-primary text-center mb-4">Contact Us</h1>
              <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
            </div>
            <p className="text-lg text-gray-700 mb-4">If you have any questions or would like to get in touch with us, feel free to contact us at:</p>
            <div className="text-lg text-gray-700">
              <p><strong> Email: </strong> <a href="mailto:contact@amdasolutions.com" className="text-primary">contact@amdasolutions.com</a></p>
              <p><strong>Phone: </strong>(347) 821 8588</p>
              <p><strong>Address:</strong> 5002 Lenker Street, Suite#108, Mechanicsburg, PA 17050</p>
            </div>
          </section>
        </div>
      </main>

      {/* Footer Section */}
      <Footer /> {/* Footer Component */}
    </React.Fragment>
  );
};

export default AboutUs;
