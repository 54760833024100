import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer"; // Import Footer component
import Navbar from "./Navbar";
import LogoImage from "../assets/images/amda_solutions_logo.png"; // adjust the path accordingly

const Products = () => {
  const tools = [
    {
      name: "Custom Step Processor",
      description: "Automate workflows with custom step processing.",
      icon: "fa-cogs",
    },
    {
      name: "Custom Document Viewer",
      description: "Enhanced document viewing functionality.",
      icon: "fa-file-alt",
    },
    {
      name: "Custom Document Search",
      description: "Search documents with precision and speed.",
      icon: "fa-search",
    },
    {
      name: "Custom WorkItem Search",
      description: "Search for work items across your ECM platform.",
      icon: "fa-tasks",
    },
    {
      name: "Custom REST Service",
      description: "Enable RESTful interactions for smooth integration.",
      icon: "fa-plug",
    },
    {
      name: "Custom Case Manager using CPE",
      description: "Manage and automate case workflows using CPE.",
      icon: "fa-briefcase",
    },
    {
      name: "Download Bulk Documents",
      description: "Download large document sets efficiently.",
      icon: "fa-download",
    },
    {
      name: "Upload Bulk Documents",
      description: "Upload large document sets seamlessly.",
      icon: "fa-upload",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Navbar />

      <main className="min-h-screen bg-gray-50 mt-24 p-6">
        <div className="max-w-5xl mx-auto">
          {/* Heading */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-primary mb-4">
              AMDA ECM Accelerator Tools
            </h1>
            <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
          </div>

          {/* Introduction Section */}
          <section className="mb-12 text-center">
            <p className="text-gray-700 mt-4">
              IBM Content Navigator (ICN) is web client, which is used by end
              users as user Interface for most the ECM products like IBM
              FileNet, IBM Content Manager, IBM Content Manger on Demand (CMOD),
              Box. ICN is developed using Java/J2EE and DOJO. ICN allows
              customization using plugin (which can be developed using
              Java/J2EE, DOJO and other Java related technologies). AMDA’s ECM
              Accelerator tools will provide most of the generic use cases,
              day-to-day functionalities, which are used by clients by reducing
              development needs for the client.
            </p>
          </section>

          {/* Limitations of ICN */}
          <section className="mb-12 text-center bg-gray-50 p-8 shadow-lg rounded-lg border border-gray-200">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">
              Limitations of ICN
            </h3>
            <ul className="text-justify text-gray-700 space-y-4 pl-6 list-disc">
              <li>
                Hard to customize by non-technical users like Product
                Owners/Business Analysts/End users. It is most configurable by
                Technical Resource.
              </li>
              <li>Not user-friendly.</li>
              <li>
                Limited Customization using out of the box (OOTB)
                functionalities.
              </li>
            </ul>
          </section>

          {/* Tools Section */}
          <section className="grid gap-8 md:grid-cols-2">
            {tools.map((tool, index) => (
              <div
                key={index}
                className="p-6 bg-white shadow-lg rounded-lg text-center"
              >
                <i className={`fa ${tool.icon} text-4xl text-primary mb-4`}></i>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {tool.name}
                </h3>
                <p className="text-gray-700">{tool.description}</p>
              </div>
            ))}
          </section>

          {/* Contact Section */}
          <section className="mt-12 text-center">
            <p className="text-gray-700 mb-4">
              To know more about our products or for a demo, contact us:
            </p>
            <a
              href="mailto:contact@amdasolutions.com"
              className="text-blue-600 hover:text-blue-800 font-semibold"
            >
              contact@amdasolutions.com
            </a>
          </section>
        </div>
      </main>

      {/* Footer Section */}
      <Footer />
    </React.Fragment>
  );
};

export default Products;
