import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Add this line at the top
import Footer from "../components/Footer"; // Import Footer component
import Navbar from "./Navbar";
import LogoImage from "../assets/images/amda_solutions_logo.png"; // Import the logo image

const career = [
  {
    jobId: "AMDA-20240801",
    jobTitle: "Functional Analyst",
    jobDescription:
      "Analyze business and other data processing problems to develop and implement solutions to complex applications.",
    jobLocation: "Mechanicsburg, PA",
    contact: "Send your resume to: contact@amdasolutions.com",
    jobInfo: {
      jobId: "AMDA-20240801",
      jobTitle: "Functional Analyst",
      jobDescription:
        "Analyze business, and other data processing problems to develop and implement solutions to complex applications problems, system administration issues, or network concerns. Perform systems management and integration functions, improve existing computer systems, and review computer system capabilities, workflow, and schedule limitations. May analyze or recommend commercially available software. All of the above duties will be performed using skills in Business & Econ Forecasting, Decisions & Strategy, & Marketing Anal & Info Mgmt.",
      jobLocation:
        "Will work in Mechanicsburg, PA and various unanticipated locations throughout the U.S. as assigned which may require relocation.",
      contact:
        "Interested applicants may send their resumes to AMDA Software Solutions, Attn. Human Resources, 5002 Lenker Street, Suite #108, Mechanicsburg, PA 17050. Resume must specifically identify all education and experience relevant to job offered.",
    },
  },
  {
    jobId: "AMDA-20240723",
    jobTitle: "Software Developer",
    jobDescription:
      "Develop and create software applications solutions. Analyze user needs and develop software solutions.",
    jobLocation: "Mechanicsburg, PA",
    contact: "Send your resume to: contact@amdasolutions.com",
    jobInfo: {
      jobId: "AMDA-20240723",
      jobTitle: "Software Developer",
      jobDescription:
        "Develop and create software applications solutions. Analyze user needs and develop software solutions. Carry out research activities and debugging of batch and online modules. Do impact analysis, design document creation, change release, deployment, monitoring and estimation. Work closely with client and development team to implement business needs. All of the above duties will be performed using Java/J2EE, Spring Boot & Angular.",
      jobLocation:
        "Will work in Mechanicsburg, PA and various unanticipated locations throughout the U.S. as assigned which may require relocation.",
      contact:
        "Interested applicants may send their resumes to AMDA Software Solutions, Attn. Human Resources, 5002 Lenker Street, Suite #108, Mechanicsburg, PA 17050. Resume must specifically identify all education and experience relevant to job offered.",
    },
  },
  {
    jobId: "AMDA-20240607",
    jobTitle: "Software Developer",
    jobDescription:
      "Develop and create software applications solutions. Analyze user needs and develop software solutions.",
    jobLocation: "Mechanicsburg, PA",
    contact: "Send your resume to: contact@amdasolutions.com",
    jobInfo: {
      jobId: "AMDA-20240607",
      jobTitle: "Software Developer",
      jobDescription:
        "Develop and create software applications solutions. Analyze user needs and develop software solutions. Carry out research activities and debugging of batch and online modules. Do impact analysis, design document creation, change release, deployment, monitoring and estimation. Work closely with client and development team to implement business needs. All of the above duties will be performed using Java/J2EE, Spring Boot & Rest API.",
      jobLocation:
        "Will work in Mechanicsburg, PA and various unanticipated locations throughout the U.S. as assigned which may require relocation.",
      contact:
        "Interested applicants may send their resumes to AMDA Software Solutions, Attn. Human Resources, 5002 Lenker Street, Suite #108, Mechanicsburg, PA 17050. Resume must specifically identify all education and experience relevant to job offered.",
    },
  },
  {
    jobId: "AMDA-20230808",
    jobTitle: "Software Developer",
    jobDescription:
      "Develop and create software applications solutions. Analyze user needs and develop software solutions.",
    jobLocation: "Mechanicsburg, PA",
    contact: "Send your resume to: contact@amdasolutions.com",
    jobInfo: {
      jobId: "AMDA-20230808",
      jobTitle: "Software Developer",
      jobDescription:
        "Develop and create software applications solutions. Analyze user needs and develop software solutions. Carry out research activities and debugging of batch and online modules. Do impact analysis, design document creation, change release, deployment, monitoring and estimation. Work closely with client and development team to implement business needs. All of the above duties will be performed using COBOL, JCL & DB2.",
      jobLocation:
        "Will work in Mechanicsburg, PA and various unanticipated locations throughout the U.S. as assigned which may require relocation.",
      contact:
        "Interested applicants may send their resumes to AMDA Software Solutions, Attn. Human Resources, 5002 Lenker Street, Suite #108, Mechanicsburg, PA 17050. Resume must specifically identify all education and experience relevant to job offered.",
    },
  },
  {
    jobId: "AMDA-20230712",
    jobTitle: "Software Developer",
    jobDescription:
      "Develop and create software applications solutions. Analyze user needs and develop software solutions. Design table structure and create tables database.",
    jobLocation: "Mechanicsburg, PA",
    contact: "Send your resume to: contact@amdasolutions.com",
    jobInfo: {
      jobId: "AMDA-20230712",
      jobTitle: "Software Developer",
      jobDescription:
        "Develop and create software applications solutions. Analyze user needs and develop software solutions. Design table structure and create tables database. Perform Data validation of the Reports. Work closely with client and QA team to implement business needs. All of the above duties will be performed using skills in Application Security, Security Architecture, Database Systems, and Enterprise Architecture.",
      jobLocation:
        "Will work in Mechanicsburg, PA and various unanticipated locations throughout the U.S. as assigned which may require relocation.",
      contact:
        "Interested applicants may send their resumes to AMDA Software Solutions, Attn. Human Resources, 5002 Lenker Street, Suite #108, Mechanicsburg, PA 17050. Resume must specifically identify all education and experience relevant to job offered.",
    },
  },
  {
    jobId: "AMDA-20220201",
    jobTitle: "Software Developer",
    jobDescription:
      "Develop and create software applications solutions. Analyze user needs and develop software solutions.",
    jobLocation: "Mechanicsburg, PA",
    contact: "Send your resume to: contact@amdasolutions.com",
    jobInfo: {
      jobId: "AMDA-20220201",
      jobTitle: "Software Developer",
      jobDescription:
        "At least five years of experience in analyzing requirements from clients and Creating high level documentation . At least five years of experience with JAVA as programming languages (e.g., JAVA etc.). At least five years coding experience with SQL or database stored procedure. At least five years’ experience in module or unit testing including executing and documenting the testing. At least five years’ experience in the design and development of applications. At least five years in either data warehousing, distributed processing, logical and physical database design, software integration, or implementation of security techniques. At least four years of experience with SAS programming. At least five years’ experience in the evaluation or comparison of various technologies.",
      jobLocation:
        "Will work in Mechanicsburg, PA and various unanticipated locations throughout the U.S. as assigned which may require relocation.",
      contact:
        "Interested applicants may send their resumes to AMDA Software Solutions, Attn. Human Resources, 5002 Lenker Street, Suite #108, Mechanicsburg, PA 17050. Resume must specifically identify all education and experience relevant to job offered.",
    },
  },
];

const CardModel = ({ index, setIndex }) => {
  // Determine if the viewport is desktop or mobile
  const isDesktop = window.innerWidth >= 768;

  if (isDesktop) {
    return (
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-all mt-6">
        {/* Close Button */}
        <button
          onClick={() => setIndex(null)}
          className="absolute top-2 right-2 bg-red-500 text-white rounded-lg p-2 hover:bg-red-600 transition-all"
        >
          ✕
        </button>

        {/* Modal Content */}
        <h3 className="text-2xl font-bold text-orange-600 mb-3">
          {career[index]["jobInfo"]["jobTitle"]}
        </h3>
        <p className="text-lg font-semibold text-secondary mb-2">
          Job ID: {career[index]["jobInfo"]["jobId"]}
        </p>
        <p className="text-sm text-gray-600 mb-4">
          {career[index]["jobInfo"]["jobDescription"]}
        </p>
        <p className="font-semibold">Location:</p>
        <p className="text-gray-600 mb-4">
          {career[index]["jobInfo"]["jobLocation"]}
        </p>
        <p className="font-semibold">Contact:</p>
        <p className="text-gray-600">{career[index]["jobInfo"]["contact"]}</p>
      </div>
    );
  } else {
    return (
      <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-all w-full max-w-md md:max-w-lg max-h-[80vh] overflow-y-scroll mt-4">
        {/* Close Button */}
        <button
          onClick={() => setIndex(null)}
          className="absolute top-2 right-2 bg-red-500 text-white rounded-lg p-2 hover:bg-red-600 transition-all"
        >
          ✕
        </button>

        {/* Modal Content */}
        <h3 className="text-2xl font-bold text-orange-600 mb-3">
          {career[index]["jobInfo"]["jobTitle"]}
        </h3>
        <p className="text-lg font-semibold text-secondary mb-2">
          Job ID: {career[index]["jobInfo"]["jobId"]}
        </p>
        <p className="text-sm text-gray-600 mb-4">
          {career[index]["jobInfo"]["jobDescription"]}
        </p>
        <p className="font-semibold">Location:</p>
        <p className="text-gray-600 mb-4">
          {career[index]["jobInfo"]["jobLocation"]}
        </p>
        <p className="font-semibold">Contact:</p>
        <p className="text-gray-600">{career[index]["jobInfo"]["contact"]}</p>
      </div>
    );
  }
};

// CareerItem component for displaying each job
const CareerItem = ({
  jobId,
  jobTitle,
  jobDescription,
  jobLocation,
  contact,
  index,
  setIndex,
}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-6 hover:shadow-xl transition-all duration-300">
      {console.log(
        jobId,
        jobTitle,
        jobDescription,
        jobLocation,
        contact,
        index
      )}
      <h3 className="text-2xl font-bold text-primary mb-3">{jobTitle}</h3>
      <p className="text-lg font-semibold text-secondary mb-2">
        Job ID: {jobId}
      </p>
      <p className="text-sm text-gray-600 mb-4">{jobDescription}</p>
      {/* <p className="font-semibold">Location:</p> */}
      <p className="text-gray-600 mb-4">{jobLocation}</p>
      <p className="font-semibold">Contact:</p>
      <p className="text-gray-600">{contact}</p>

      {/* Enhanced Button */}
      <button
        onClick={() => setIndex(index)}
        className="mt-4 bg-orange-500 text-white py-2 px-4 rounded-2xl text-lg font-semibold hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 transition duration-300"
      >
        Get More Info
      </button>
    </div>
  );
};

// Filters for job type or location
// const FilterSection = ({ setFilter }) => (
//   <div className="bg-white shadow-md p-6 rounded-lg mb-8">
//     <h3 className="text-xl font-bold text-primary mb-4">Filter Jobs</h3>
//     <div className="space-y-4">
//       <div>
//         <label className="block text-sm font-medium text-gray-700">Job Type</label>
//         <select
//           className="w-full p-3 border border-gray-300 rounded-md"
//           onChange={(e) => setFilter({ jobType: e.target.value })}
//         >
//           <option value="">Select Job Type</option>
//           <option value="Full-time">Full-time</option>
//           <option value="Part-time">Part-time</option>
//           <option value="Internship">Internship</option>
//         </select>
//       </div>
//       <div>
//         <label className="block text-sm font-medium text-gray-700">Location</label>
//         <select
//           className="w-full p-3 border border-gray-300 rounded-md"
//           onChange={(e) => setFilter({ location: e.target.value })}
//         >
//           <option value="">Select Location</option>
//           <option value="Mechanicsburg, PA">Mechanicsburg, PA</option>
//           <option value="Various Locations">Various Locations</option>
//         </select>
//       </div>
//     </div>
//   </div>
// );

const Header = () => {
  const [filter, setFilter] = useState({}); // For managing filters
  const [index, setIndex] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* Header Section */}
      <Navbar />
      {/* Main Content Section */}
      <main className="mt-24 p-6">
        <div className="text-center  mb-8">
          <h1 className="text-4xl font-bold text-primary text-center mb-4">
            Open Positions
          </h1>
          <div className="w-24 mx-auto border-t-2 border-primary mt-2"></div>
        </div>

        {/* Filter Section */}
        {/* <FilterSection setFilter={setFilter} /> */}
        {/* {console.log("Current index : ", index, typeof index == "number", typeof index)} */}
        {typeof index == "number" ? (
          <CardModel index={index} setIndex={setIndex} />
        ) : null}
        {/* Job Listings */}
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6 bg-gray-50">
          {career.map((item, index) => {
            return (
              <CareerItem
                jobId={item.jobId}
                jobTitle={item.jobTitle}
                jobDescription={item.jobDescription}
                // jobLocation={item.jobLocation}
                contact={item.contact}
                index={index}
                setIndex={setIndex}
              />
            );
          })}

          {/* <CareerItem
            jobId="AMDA-20240801"
            jobTitle="Functional Analyst"
            jobDescription="Analyze business and other data processing problems to develop and implement solutions to complex applications."
            jobLocation="Mechanicsburg, PA"
            contact="Send your resume to: HR@amdasolutions.com"
          />
          <CareerItem
            jobId="AMDA-20240723"
            jobTitle="Software Developer"
            jobDescription="Develop software applications using Java/J2EE, Spring Boot, and Angular."
            jobLocation="Various Locations"
            contact="Send your resume to: HR@amdasolutions.com"
          />
          <CareerItem
            jobId="AMDA-20240724"
            jobTitle="Java Developer"
            jobDescription="Develop software applications using Java/J2EE, Spring Boot, and Angular."
            jobLocation="Various Locations"
            contact="Send your resume to: HR@amdasolutions.com"
          /> */}
          {/* Add more CareerItem components as needed */}
        </section>
        {/* Contact Section */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-7xl mx-auto px-6 md:px-12 lg:px-16">
            <h2 className="text-4xl font-bold text-center text-primary mb-12">
              Get In Touch
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              {/* Form Section */}
              <div className="bg-white shadow-lg rounded-lg p-8">
                <form className="space-y-6">
                  <div>
                    <label className="block text-lg font-medium text-gray-700 mb-1">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div>
                    <label className="block text-lg font-medium text-gray-700 mb-1">
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div>
                    <label className="block text-lg font-medium text-gray-700 mb-1">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                      placeholder="Enter subject"
                    />
                  </div>
                  <div>
                    <label className="block text-lg font-medium text-gray-700 mb-1">
                      Message
                    </label>
                    <textarea
                      className="w-full p-3 border border-gray-400 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                      placeholder="Enter your message"
                      rows="4"
                    ></textarea>
                  </div>
                  <button className="w-1/2 px-6 py-3 bg-primary text-white font-semibold rounded-lg shadow-md hover:bg-accent transition-all">
                    Send Message
                  </button>
                </form>
              </div>

              {/* Information Section */}
              <div className="flex flex-col justify-center space-y-6 bg-gradient-to-br from-orange-400 to-orange-500 text-white rounded-lg p-8 shadow-lg">
                <h3 className="text-3xl font-semibold">Contact Information</h3>
                <p className="text-lg">
                  Reach out to us for any queries or assistance. Our team is
                  always ready to help you with your requirements.
                </p>
                <div className="space-y-4">
                  <p>
                    <strong>Phone:</strong> (347) 821 8588
                  </p>
                  <p>
                    <strong>Email:</strong> contact@amdasolutions.com
                  </p>
                  <p>
                    <strong>Address:</strong> 5002 Lenker Street, Suite#108,
                    Mechanicsburg, PA 17050.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Footer Section */}
      <Footer /> {/* Add Footer at the bottom of the page */}
    </React.Fragment>
  );
};

export default Header;
