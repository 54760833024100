import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoImage from "../assets/images/amda_solutions_logo.png";

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
  
    // Determine viewport size
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
      };
  
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 0); // Add scroll shadow
      };
  
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll);
  
      // Set initial values
      handleResize();
  
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    // Desktop Header
    const DesktopHeader = () => (
      <header
        className={`bg-secondary text-white shadow-lg flex items-center justify-between p-4 fixed top-0 left-0 right-0 z-50 transition-all duration-300`}
      >
        <div className="flex items-center">
          <img src={LogoImage} alt="AMDA Logo" className="h-16 w-auto" />
          <span className="ml-3 font-extrabold text-2xl text-primary">
            AMDA Software Solutions
          </span>
        </div>
  
        <div className="ml-auto flex items-center gap-6">
          <nav className="flex gap-6 text-lg font-bold">
            <Link
              to="/"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Home
            </Link>
            <Link
              to="/about-us"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              About
            </Link>
            <Link
              to="/product"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Products
            </Link>
            <Link
              to="/services"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Services
            </Link>
            <Link
              to="/careers"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Careers
            </Link>
            <Link
              to="/contact-us"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Contact
            </Link>
          </nav>
          <Link
            to="/employee-login"
            className="btn-primary px-4 py-2 rounded-lg font-bold text-white bg-orange-500 hover:bg-orange-600 transition-all duration-300"
          >
            Employee Login
          </Link>
        </div>
      </header>
    );
  
    // Mobile Header
    const MobileHeader = () => (
      <header
        className={`bg-secondary text-white shadow-lg flex items-center justify-between p-4 fixed top-0 left-0 right-0 z-50 transition-all duration-300`}
      >
        {!isMobileMenuOpen && (
          <div className="flex items-center">
            <img src={LogoImage} alt="AMDA Logo" className="h-16 w-auto" />
            <span className="ml-3 font-extrabold text-2xl text-primary mr-4">
              AMDA Software Solutions
            </span>
          </div>
        )}
  
        {/* Toggle Button for Mobile */}
        <div className="ml-auto md:hidden">
          <button
            className="text-white focus:outline-none text-3xl"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <span>&times;</span> : <span>&#9776;</span>}
          </button>
        </div>
  
        {/* Navigation menu */}
        <div
          className={`${
            isMobileMenuOpen ? "flex" : "hidden"
          } flex-col items-center justify-center fixed top-0 left-0 w-full h-screen bg-secondary text-white md:static md:flex md:flex-row md:gap-6 text-lg font-bold`}
        >
          {isMobileMenuOpen && (
            <button
              className="absolute top-4 right-4 text-white text-3xl focus:outline-none"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              &times;
            </button>
          )}
          <nav className="flex flex-col items-center gap-6 md:flex-row">
            <Link
              to="/"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Home
            </Link>
            <Link
              to="/about-us"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              About
            </Link>
            <Link
              to="/product"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Products
            </Link>
            <Link
              to="/services"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Services
            </Link>
            <Link
              to="/careers"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Careers
            </Link>
            <Link
              to="/contact-us"
              className="text-primary hover:text-orange-500 transition-colors duration-300"
            >
              Contact
            </Link>
          </nav>
          <Link
            to="/employee-login"
            className="btn-primary px-4 py-2 rounded-lg font-bold text-white bg-orange-500 hover:bg-orange-600 transition-all duration-300 mt-4"
          >
            Employee Login
          </Link>
        </div>
      </header>
    );
  
    return <>{isMobile ? <MobileHeader /> : <DesktopHeader />}</>;
  };

  export default Navbar;