import React, { useState, useEffect } from "react";
import gsap from "gsap";
import bgImage from "../assets/images/AMDA-Background-Cover.jpeg";

const Hero = () => {
  const [currentWord, setCurrentWord] = useState(0);

  const words = [
    { text: "Ease", color: "text-orange-500 font-bold" },
    { text: "Growth", color: "text-red-500 font-bold" },
    { text: "Success", color: "text-green-500 font-bold" },
  ];

  // Use interval to change the word every 2.5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, 2500);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [words.length]);

  // GSAP animation for text elements
  useEffect(() => {
    const elements = document.querySelectorAll(".step");
    if (elements.length) {
      gsap.fromTo(
        elements,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 0.5, stagger: 0.3 }
      );
    }
  }, []);

  return (
    <main  className="relative min-h-screen flex items-center justify-center overflow-hidden bg-cover bg-center">
      {/* Background Image Container */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          opacity: 0.8, // Adjust the opacity of the background image here
        }}
      ></div>

      {/* Dark Overlay */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-black opacity-80"
        style={{ zIndex: -1 }}
      ></div>

      {/* Content */}
      <div className="bg-white bg-opacity-0 p-10 rounded-lg shadow-lg text-center max-w-3xl relative z-10">
        <header className="mb-4">
          <h1 className="text-xl font-extrabold text-white mb-4">
            WELCOME TO AMDA SOFTWARE SOLUTIONS
          </h1>
          <h1 className="text-2xl font-bold text-white">
            "Develop and deliver high-quality software services to meet business needs"
          </h1>
        </header>

        <section className="py-6">
          <h1 className="text-3xl font-bold text-white mb-4">
            Transform Your Business with AMDA Software Solutions
          </h1>
          <div className="relative h-16 mt-4">
            <p className="text-2xl font-bold text-white">
              Redefining Business Technology need with a{" "}
              <span className="relative inline-block h-6">
                {words.map((word, index) => (
                  <span
                    key={index}
                    className={`absolute transition-all duration-700 ease-in-out 
                      ${index === currentWord ? "opacity-100 translate-y-0" : "opacity-0 translate-y-full"} 
                      ${word.color}`}
                    style={{ top: 0, left: 0 }}
                  >
                    {word.text}
                  </span>
                ))}
              </span>
              .
            </p>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Hero;
