import React from "react";
import ascendum from "../../assets/images/Logos/ascendum.jpg"
import Collabera from "../../assets/images/Logos/Collabera.png"
import DMI from "../../assets/images/Logos/DMI.png"
import IBS from "../../assets/images/Logos/IBS Logo-01.webp"
import Integrity from "../../assets/images/Logos/IntegrityFirstSolutions.png"
import Noblesoft from "../../assets/images/Logos/Noblesoft Solutions.png"
import Randstad from "../../assets/images/Logos/Randstad.png"
import Revelliem from "../../assets/images/Logos/Revellie.png"
import SageIt from "../../assets/images/Logos/Sage-IT.png"
import StackNexus from "../../assets/images/Logos/StackNexus.png"
import Syllogisteks  from "../../assets/images/Logos/Syllogisteks.png"
import TekSystems from "../../assets/images/Logos/TekSystems.png"

const clients = [
  {
    name: 'Ascendum',
    logo: ascendum,
  },
  {
    name: 'Collabera',
    logo: Collabera,
  },
  {
    name: 'DMI',
    logo: DMI,
  },
  {
    name: 'IBS',
    logo: IBS,
  },
  {
    name: 'Integrity',
    logo: Integrity,
  },
  {
    name: 'Noblesoft',
    logo: Noblesoft,
  },
  {
    name: 'Randstad',
    logo: Randstad,
  },
  {
    name: 'Revelliem',
    logo: Revelliem,
  },
  {
    name: 'SageIt',
    logo: SageIt,
  },
  {
    name: 'StackNexus',
    logo: StackNexus,
  },
  {
    name: 'Syllogisteks',
    logo: Syllogisteks,
  },
  {
    name: 'TekSystems',
    logo: TekSystems,
  },
  
  // Add as many clients as needed
];

const ClientsSection = () => (
  <section className="bg-background py-8">
    <h2 className="text-2xl font-bold text-center text-primary mb-4">
      Trusted by
    </h2>
    <div className="relative overflow-hidden">
      <div className="flex animate-scroll gap-6 py-4">
        {clients.concat(clients).map((client, index) => (
          <img
            key={index}
            src={client.logo}
            alt={client.name}
            className="h-12 grayscale hover:grayscale-0"
          />
        ))}
      </div>
    </div>
  </section>
);

export default ClientsSection;
