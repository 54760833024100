import React from "react";
import AMDA_Solutions_Logo from "../assets/images/amda_solutions_logo.png";
import eVerify from "../assets/images/E-verify.png";
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa"; // Make sure you have react-icons installed

const Footer = () => {
  return (
    <footer className="bg-orange-500 text-white py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Column 1: Company Logo and Certifications */}
          <div className="flex flex-col items-center">
            <img src={AMDA_Solutions_Logo} alt="AMDA Logo" className="h-20 w-auto mb-4" />
            <div className="flex flex-col items-center space-y-2">
              <img src={eVerify} alt="Certification" className="h-12 w-auto" />
              <p><strong>We participate in E-Verify Program</strong></p>
            </div>
          </div>

          {/* Column 2: Company Information */}
          <div>
            <h3 className="font-bold mb-2">Company</h3>
            <ul>
              <li>About</li>
              <li>Careers</li>
            </ul>
          </div>

          {/* Column 3: Our Address */}
          <div>
            <h3 className="font-bold mb-2">Headquarters:</h3>
            <p>5002 Lenker Street, Suite#108, Mechanicsburg, PA 17050</p>
            <p><strong>Phone: </strong>(347) 821 8588</p>
            <p><strong>Email:</strong> contact@amdasolutions.com</p>
          </div>
        </div>

        {/* Horizontal Line */}
        <hr className="my-6 border-t border-white opacity-50" />

        {/* Footer Bottom Section */}
        <div className="flex justify-between items-center text-5m">
          {/* Left Section */}
          <p>AMDA Software Solutions. © 2024. All Rights Reserved</p>

          {/* Right Section */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
