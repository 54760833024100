import logo from './logo.svg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import RouterComp from "./routes"

function App() {
  return (
    <div className="App">
      <RouterComp />
  
    </div>
  );
}

export default App;
